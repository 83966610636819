.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(35,158,135);
    background: linear-gradient(90deg, rgba(35,158,135,1) 0%, rgba(16,108,115,1) 100%);
}

.btn-dark-blue {
    color: #FFFFFF !important;
    background-color: #25283D !important;
    transition: all 0.2s !important;
}

.login-button:hover {
    background-color: #333753 !important;
}

.input-style {
    border-style: solid;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 8px;
    width: 170px;
    border-color: #e3e3e3;
}

.input-style:focus {
    box-shadow: 1px 1px 4px rgb(194, 216, 203);
    -moz-box-shadow: 1px 1px 4px rgb(194, 216, 203);
    -webkit-box-shadow: 1px 1px 4px rgb(194, 216, 203);
    transition: all 0.30s ease-in-out;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
}

.login-form-container {
    padding: 25px 25px 15px 25px;
    border-radius: 4px;
    /*background-color: #f1f1f1;*/
    /*box-shadow: 0px 0px 8px 1px #333753;*/
    display: grid;
    justify-items: center;
    align-items: center;
    column-width: auto;
}

@media screen and (max-width: 768px), (max-height: 500px) {
    .login-form-container {
        height: 100%;
        width: 100%;
        border-radius: 0px;
        padding: 0;
    }
}

@media screen and (max-height: 768px) {
    .login-form-container {
        margin-top: 62px;
    }
}

.react-datepicker__time-list {
    padding: unset !important;
}

.date-label {
    width: 145px;
}

.custom-link:hover {
    text-decoration: underline;
}

.sidebar-content {
    margin: 15px 15px 15px 205px;
}

.menu-item-top {
    margin-left: 160px;
}

/* Sidebar menu */
@media only screen and (max-width: 768px) {
    .left-sidebar {
        display: none;
    }

    .sidebar-content, .menu-item-top {
        margin-left: 15px;
    }

    .y-padding-none {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.input-color {
    height: 38px;
    padding: 2px !important;
}

.color-sample {
    width: 84px;
    height: 30px;
    border-radius: 3px;
    border-color: #dededf;
    border-style: solid;
    border-width: 2px;
}

.align-right-large {
    text-align: left !important;
}

@media only screen and (min-width: 768px) {
    .align-right-large {
        text-align: right !important;
    }
}

#login-form {
    min-width: 100px;
}