html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

::placeholder {
  color: black !important;
}

input {
  border-color: #959595 !important;
}